// Constructor
function i18n() {

	this.lang = "en";
	this.i18nTimer = -1;
	this.i18nDelay = 30000;

	// Reset a few things
	// this.i18nFlipResetTimer();

	// Init event bindings
	$(".language-switcher").bind("mousedown", $.proxy(this.flip, this));

	// Init
	this.set(this.lang);
}


i18n.prototype.set = function (lang) {

	if (lang == "fr") {
		this.lang = "fr";
		$("body").removeClass("i18n-en").addClass("i18n-fr");
	} else {
		this.lang = "en";
		$("body").removeClass("i18n-fr").addClass("i18n-en");
	}
}


i18n.prototype.flip = function () {

	if (this.lang == "fr") {
		setTimeout(function () {
			$("body").removeClass("i18n-fr").addClass("i18n-en");
		}, 250);
		this.lang = "en";
	} else {
		setTimeout(function () {
			$("body").removeClass("i18n-en").addClass("i18n-fr");
		}, 250);
		this.lang = "fr";
	}

	// Flip animation
	$(".flipping-i18n").addClass("flip-out");
	setTimeout(function () {
		$(".flipping-i18n").removeClass("flip-out");
	}, 300);

	// Fade animation
	$(".fading-i18n").addClass("fade-out");
	setTimeout(function () {
		$(".fading-i18n").removeClass("fade-out");
	}, 300);

	// Dispatch event
	$.event.trigger({
		type: "i18nFlip",
		lang: this.lang
	});

};


i18n.prototype.i18nFlipResetTimer = function () {

	if (this.i18nTimer !== -1) {
		clearInterval(this.i18nTimer);
	}
	this.i18nTimer = setInterval($.proxy(this.flip, this), this.i18nDelay);
};

// Expose i18n
window.i18n = i18n;