//  Constructor
function Screen(apiBase) {
	this.apiBase = apiBase;
	this.priceFactor = 1;
	this.totalKm = 5160;
	this.refreshUIDelay = 10000;
	this.refreshBikesDelay = 2000;
	this.refreshDataCounter = 0;

	this.maxCash = 10000;

	this.switchPagesPreformanceDelay = 10000;
	this.switchPagesUniversityDelay = 15000;


	this.dataParticipants = {};
	this.dataUniversities = {};
	this.dataBikes = {};
	this.dataTotal = {};

	// Get data from API
	this.refreshData();

	// Automatic toggle
	this.interval = setInterval($.proxy(this.refreshData, this), this.refreshUIDelay);
	this.switchPageUniverstiyInteval = setInterval($.proxy(this.switchPages, this, "#top-university"), this.switchPagesUniversityDelay);

	setInterval(function () {
		$("#title-completed").toggleClass("reversed");
	}, 600);

	// Space key handler
	$(document).bind('keypress', $.proxy(function (e) {
		if (e.keyCode == 32) {
			// this.gotoResults();
			console.log("gotoResults is disabled")
		}
	}, this));

}

Screen.prototype.refreshData = function (callback) {

	this.refreshDataCounter = 0;

	// Load default settings
	$.ajax({
		dataType: "json",
		url: this.apiBase + "top_univesities",
		//async: false,
		success: $.proxy(this.refreshDataUniversitiesCallback, this, callback),
		error: $.proxy(this.refreshDataCount, this),
	});

	$.ajax({
		dataType: "json",
		url: this.apiBase + "total",
		success: $.proxy(this.refreshTotalCallback, this, callback),
		error: $.proxy(this.refreshDataCount, this),
	});
}

Screen.prototype.refreshDataUniversitiesCallback = function (callback, data) {

	if (JSON.stringify(data) === JSON.stringify(this.dataUniversities)) {
		console.log("Same data received");
		return;
	}

	this.dataUniversities = data;

	$("#top-university .top-item").addClass("empty")
	$(".name, .cash", "#top-university .top-item").html("");
	for (var i = 0; i < data.content.length; i++) {
		var $el = $("#top-university .top-item").eq(i);
		$el.find(".name").html(data.content[i].name);
		$el.find(".cash").html(data.content[i].cash);
		$el.removeClass("empty");
	}

	$("#top-university .page-1").toggleClass("has-content", (data.content.length > 0));
	$("#top-university .page-2").toggleClass("has-content", (data.content.length > 10));
	$("#top-university .page-3").toggleClass("has-content", (data.content.length > 20));

	if (callback && this.refreshDataCounter == 3) {
		callback();
	}
}


Screen.prototype.refreshTotalCallback = function (callback, data) {
	this.dataTotal = data;


	var cash = Math.floor(parseFloat(data.content.cash) * this.priceFactor);
	if (isNaN(cash)) cash = 0;

	var previousCash = parseInt($("#global-content .total-cash .count").text());
	$("#global-content .total-cash .count").countTo({
		from: previousCash,
		speed: 1000,
		refreshInterval: 50,
		decimals: 0,
		formatter: function (value, options) {
			return value.toFixed(options.decimals);
		},
		to: cash
	});


	$("#finale .cash .count").html(cash)

	// // Update path
	// var iw = $("#path").width();
	// var ih = $("#path").height();
	// var progress = km / this.totalKm;
	// var clipVal = 'rect(0px, ' + iw + 'px, ' + ih + 'px, ' + parseInt(iw * (1.0 - progress)) + 'px)';
	// $("#path-line").css("clip", clipVal);

	if (cash >= this.maxCash) {
		$("#title-in-progress").fadeOut();
		$("#title-completed").fadeIn();
	}

	if (callback && this.refreshDataCounter == 3) {
		callback();
	}
}


Screen.prototype.switchPages = function (selector) {

	console.log("switchPages");

	$(selector).each(function (i, n) {
		var pageCount = $(".page.has-content", n).length;
		var currentPageIndex = $(".page.active", n).data("index");

		if (typeof currentPageIndex == "undefined") {
			currentPageIndex = 0;
		}

		if (pageCount <= 1) {
			return;
		}

		var newPageIndex = (currentPageIndex + 1) % pageCount;
		$(".page", n).eq(currentPageIndex).removeClass("active");
		var pageWidth = $(".page", n).first().width();
		var pagesWrapperOffset = (-pageWidth * newPageIndex);

		setTimeout($.proxy(function (newPageIndex, pagesWrapperOffset) {
			$(".page", n).eq(newPageIndex).addClass("active");
			$(".pages-wrapper", n).css("left", pagesWrapperOffset + "px");
		}, this, newPageIndex, pagesWrapperOffset), 400)
	});
}


Screen.prototype.gotoResults = function () {

	clearInterval(this.interval);

	this.interval = setInterval($.proxy(this.refreshData, this), 15000);

	$(".km, .cash", "#finale").css("opacity", 0);

	$("#top-participants, #top-university, .bottom-content").fadeOut(400, $.proxy(function () {
		$("#finale").fadeIn();

		this.updateDOM();

		// Show KM
		setInterval(function () {
			$("#finale .km").animate({
				opacity: 1.0
			}, 400);
		}, 1000);

		// Show cash
		setInterval(function () {
			$("#finale .cash").animate({
				opacity: 1.0
			}, 400);
		}, 2000);

	}, this));
}

Screen.prototype.refreshDataCount = function () {
	this.refreshDataCounter++;
}

Screen.prototype.showElementsSequence = function (selector) {

	// Hide and display items in sequence
	$(selector).hide().each(function (i, n) {
		setTimeout((function (n) {
			return function () {
				$(n).fadeIn();
			}
		})(n), i * 500);
	});

}
