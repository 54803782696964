var DEBUG_OFF = 0;
var DEBUG_ON = 1;

$(document).ready(function () {
	if (window.appType == "screen") {
		window.app = new Screen("api/", DEBUG_ON);
		window.i18n = new i18n();
	} else {
		window.app = new Tablet("api/", DEBUG_ON);
	}
});
